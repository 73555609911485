import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import AudioStreamPlayer from './components/Audio/Audio';
import SocialMedia from './components/SocialMedia/SocialMedia';
import Columna2 from './components/Columna2/Columna2'

function App() {
  return (
    <div className="App">
      <Container fluid className="min-vh-100">
        <Row className="min-vh-100">
            <Col sm={4} className="bg-light min-vh-100 d-flex flex-column gap-2 m-0">
                <Row>
                    <AudioStreamPlayer />
                </Row>
                <Row className="d-flex justify-content-center align-items-center flex-grow-sm-1 flex-grow-0">
                    <SocialMedia />
                </Row>
            </Col>
            <Col className="min-vh-100 d-none d-md-block m-0 p-0">
              <Columna2 />
            </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;