import './Columna2.scss';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

export default function Columna2() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false}>
      <Carousel.Item>
        <div className="carousel-overlay">
          <img src="/images/image-01.jpg" className="d-block w-100 image-carrousel" alt="First slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-overlay">
          <img src="/images/image-02.jpg" className="d-block w-100 image-carrousel" alt="Second slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-overlay">
          <img src="/images/image-03.jpg" className="d-block w-100 image-carrousel" alt="Third slide" />
        </div>
      </Carousel.Item>
    </Carousel>
  );
}