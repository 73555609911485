import { useState, useEffect } from 'react';
import './Programacion.scss';

export default function ProgramasEnVivo() {
    const [programa, setPrograma] = useState('');

    const obtenerPrograma = () => {
        const fecha = new Date();
        const dia = fecha.getDay();
        const hora = fecha.getHours();
        const minutos = fecha.getMinutes();
        const horaActual = hora + (minutos / 60);

        console.log(horaActual)

        let programaActual = '';

        if (dia === 1) { // Lunes
            if (horaActual >= 8 && horaActual < 10.30) {
                programaActual = 'Patricio Flaherty';
            } else if (horaActual >= 10.30 && horaActual < 12) {
                programaActual = 'Las mañanas de la radio';
            } else if (horaActual >= 12 && horaActual < 14) {
                programaActual = 'Una copla en la llanura';
            }  else if (horaActual >= 14 && horaActual < 16) {
                programaActual = 'El retrovisor';
            } else if (horaActual >= 16 && horaActual < 18) {
                programaActual = 'Nestor y la Movida Tropical';
            } else if (horaActual >= 18 && horaActual < 20) {
                programaActual = 'Para que aprendas';
            } else if (horaActual >= 20 && horaActual < 22) {
                programaActual = 'Conectados con la musica de Fabian Quintana';
            } else {
                programaActual = 'No hay programas en vivo';
            }
        } else if (dia === 2) { // Martes
            if (horaActual >= 8 && horaActual < 10.30) {
                programaActual = 'Patricio Flaherty';
            } else if (horaActual >= 10.30 && horaActual < 12) {
                programaActual = 'Las mañanas de la radio';
            } else if (horaActual >= 12 && horaActual < 14) {
                programaActual = 'Una copla en la llanura';
            } else if (horaActual >= 14 && horaActual < 16) {
                programaActual = 'El retrovisor';
            } else if (horaActual >= 16 && horaActual < 18) {
                programaActual = 'Nestor y la Movida Tropical';
            } else if (horaActual >= 18 && horaActual < 20) {
                programaActual = 'Para que aprendas';
            } else if (horaActual >= 20 && horaActual < 22) {
                programaActual = 'Conectados con la musica de Fabian Quintana';
            } else {
                programaActual = 'No hay programas en vivo';
            }
        } else if (dia === 3) { // Miercoles
            if (horaActual >= 8 && horaActual < 10.30) {
                programaActual = 'Patricio Flaherty';
            } else if (horaActual >= 10.30 && horaActual < 12) {
                programaActual = 'Las mañanas de la radio';
            } else if (horaActual >= 12 && horaActual < 14) {
                programaActual = 'Una copla en la llanura';
            } else if (horaActual >= 14 && horaActual < 16) {
                programaActual = 'El retrovisor';
            } else if (horaActual >= 16 && horaActual < 18) {
                programaActual = 'Nestor y la Movida Tropical';
            } else if (horaActual >= 18 && horaActual < 20) {
                programaActual = 'Para que aprendas';
            } else if (horaActual >= 21 && horaActual < 22) {
                programaActual = 'Raices Gauchas';
            } else {
                programaActual = 'No hay programas en vivo';
            }
        } else if (dia === 4) { // Jueves
            if (horaActual >= 8 && horaActual < 10.30) {
                programaActual = 'Patricio Flaherty';
            } else if (horaActual >= 10.30 && horaActual < 12) {
                programaActual = 'Las mañanas de la radio';
            } else if (horaActual >= 12 && horaActual < 14) {
                programaActual = 'Una copla en la llanura';
            } else if (horaActual >= 14 && horaActual < 16) {
                programaActual = 'El retrovisor';
            } else if (horaActual >= 16 && horaActual < 18) {
                programaActual = 'Nestor y la Movida Tropical';
            } else if (horaActual >= 18 && horaActual < 20) {
                programaActual = 'Para que aprendas';
            } else if (horaActual >= 20 && horaActual < 22) {
                programaActual = 'Conectados con la musica de Fabian Quintana';
            } else {
                programaActual = 'No hay programas en vivo';
            }
        } else if (dia === 5) { // Viernes
            if (horaActual >= 8 && horaActual < 10.30) {
                programaActual = 'Patricio Flaherty';
            } else if (horaActual >= 10.30 && horaActual < 12) {
                programaActual = 'Las mañanas de la radio';
            } else if (horaActual >= 12 && horaActual < 14) {
                programaActual = 'Una copla en la llanura';
            } else if (horaActual >= 14 && horaActual < 16) {
                programaActual = 'El retrovisor';
            } else if (horaActual >= 16 && horaActual < 17) {
                programaActual = 'Nestor y la Movida Tropical';
            } else if (horaActual >= 17 && horaActual < 19) {
                programaActual = 'Razones para vivir';
            } else if (horaActual >= 20 && horaActual < 22) {
                programaActual = 'Conectados con la musica de Fabian Quintana';
            } else {
                programaActual = 'No hay programas en vivo';
            }
        } else if (dia === 6) { // Sabado
            if (horaActual >= 18 && horaActual < 20) {
                programaActual = 'Kalelo Lugo y el Bailantazo';
            } else {
                programaActual = 'No hay programas en vivo';
            }
        } else if (dia === 0) { // Domingo
            if (horaActual >= 8 && horaActual < 13) {
                programaActual = 'El Baul de los Recuerdos';
            } else if (horaActual >= 18 && horaActual < 20) {
                programaActual = 'Kalelo Lugo y el Bailantazo';
            } else {
                programaActual = 'No hay programas en vivo';
            }
        }

        setPrograma(programaActual);
    };

    useEffect(() => {
        obtenerPrograma();
        const intervalo = setInterval(obtenerPrograma, 60000);

        return () => clearInterval(intervalo);
    }, []);

    return (
        <div>
            <h3>Programa en Vivo</h3>
            <h1 className='m-2 bg-rounded text-dark fw-bold'>{programa}</h1>
        </div>
    );
}