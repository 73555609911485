import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import ProgramasEnVivo from './Programacion/Programacion'

export default function SocialMedia() {
    const [activo, setActivo] = useState(false);
    

    return (
        <Col className='align-items-center'>
            <Row className='d-flex mb-3'>
                <h4>Seguinos en nuestras Redes</h4>
            </Row>
            <Row className='d-flex justify-content-center mb-3'>
                <Button className='w-50' variant="secondary" href='https://www.facebook.com/RadioCasbas' target="_blank">Facebook</Button>
            </Row>
            <Row className='d-flex justify-content-center mb-3'>
                <Button className='w-50' variant="secondary" href='https://www.instagram.com/radiocasbas' target="_blank">Instagram</Button>
            </Row>
            <Row className='d-flex justify-content-center mb-3'>
                <Button className='w-50' variant="secondary" href='https://www.youtube.com/@fmcasbas98.7' target='_blank'>Youtube</Button>
            </Row>
            <Row className='d-flex justify-content-center mb-3'>
                <Button className='w-50' variant="secondary" href='mailto:fmcasbas95.5@gmail.com' >Email</Button>
            </Row>
            <Row className='d-flex justify-content-center mb-3'>
                <Button className='w-50' variant="secondary" href="https://wa.me/54292315423024" target="_blank">Whatsapp</Button>
            </Row>
            <Row className='d-flex justify-content-center mb-3'>
                <Button className='w-50' variant="danger" onClick={() => setActivo(!activo)}>Programación</Button>
            </Row>

            {activo && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 9999
                }}>
                    <div>
                        <ProgramasEnVivo />
                        <Button variant="light" onClick={() => setActivo(false)}>Cerrar</Button>
                    </div>
                </div>
            )}
        </Col>
    );
}