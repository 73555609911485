import { useState } from "react";
import ReactPlayer from "react-player";
import { Play, Square } from "lucide-react";
import './Audio.scss'

export default function AudioStreamPlayer() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);

  const streamUrl = "https://stream.zeno.fm/tce54a3wp68uv";

  return (
    <div className="w-full h-[70px] flex items-center justify-center bg-gray-900 text-white p-4">
      <img src="/radiocasbas-blanco.png" alt="Logo de Radio Casbas" className="logotipo" />
      <button onClick={() => setIsPlaying(!isPlaying)} className="p-2 bg-blue-500 rounded-full mx-2 buttonplay">
        {isPlaying ? <Square size={24} className="text-dark"  /> : <Play size={24} className="text-dark"  />}
      </button>
      <input
        type="range"
        min="0"
        max="1"
        step="0.1"
        value={volume}
        onChange={(e) => setVolume(parseFloat(e.target.value))}
          className="ml-4 w-40 h-2 bg-gray-600 rounded-full appearance-none cursor-pointer hover:bg-gray-500 transition"
      />
      <ReactPlayer
        url={streamUrl}
        playing={isPlaying}
        volume={volume}
        width="0"
        height="0"
      />
    </div>
  );
}
